/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  StoreHourData,
  ConvertedStoreHoursType,
  CustomizedStoreHours,
} from '@/components/StoreLocator/types';
import { daysArray } from '@/constants/date';
import type { Locale } from '@/types/i18n';
import { isDuringBusinessHours } from '@/utils/isDuringBusinessHours';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export function getCurrentWeek(locale: Locale): Array<string> {
  //Get today and next six days to fill out the current 'week'.
  const week = [
    dayjs().add(0, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(1, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(2, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(3, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(4, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(5, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
    dayjs().add(6, 'day').toDate().toLocaleDateString(locale, { weekday: 'long' }),
  ];
  return week;
}

export function dateIsWithinCurrentWeek(date: string) {
  const dateValue = dayjs(date);
  const startOfWeek = dayjs().startOf('week');
  const endOfNextWeek = startOfWeek.add(7, 'day');
  const result = dateValue.isBefore(endOfNextWeek) || dateValue.isSame(endOfNextWeek);

  return result;
}

export function translateDayToEnglish(day: string) {
  switch (day) {
    case 'lunes':
    case 'segunda-feira':
      return 'Monday';

    case 'martes':
    case 'terça-feira':
      return 'Tuesday';

    case 'miércoles':
    case 'quarta-feira':
      return 'Wednesday';

    case 'jueves':
    case 'quinta-feira':
      return 'Thursday';

    case 'viernes':
    case 'sexta-feira':
      return 'Friday';

    case 'sábado':
      return 'Saturday';

    case 'domingo':
      return 'Sunday';

    default:
      return 'Sunday';
  }
}

export function translateDayToSpanish(day: string): string {
  switch (day) {
    case 'Monday':
      return 'Lunes';

    case 'Tuesday':
      return 'Martes';

    case 'Wednesday':
      return 'Miércoles';

    case 'Thursday':
      return 'Jueves';

    case 'Friday':
      return 'Viernes';

    case 'Saturday':
      return 'Sábado';

    case 'Sunday':
      return 'Domingo';

    default:
      return 'Domingo';
  }
}

export function translateMonthToSpanish(month: string): string {
  switch (month) {
    case 'January':
      return 'Enero';

    case 'February':
      return 'Febrero';

    case 'March':
      return 'Marzo';

    case 'April':
      return 'Abril';

    case 'May':
      return 'Mayo';

    case 'June':
      return 'Junio';

    case 'July':
      return 'Julio';

    case 'August':
      return 'Agosto';

    case 'September':
      return 'Septiembre';

    case 'October':
      return 'Octubre';

    case 'November':
      return 'Noviembre';

    default:
      return 'Diciembre';
  }
}

export const convertStoreHourData = (storeHourData: StoreHourData, locale: Locale) => {
  dayjs.extend(isSameOrAfter);
  const { holidayHours, hours } = storeHourData;

  const hasHolidayHours = Array.isArray(holidayHours) && holidayHours.length > 0;

  // only use holiday hours that are in the future
  const filteredHolidayHours = hasHolidayHours
    ? holidayHours.filter((elem) => {
        const { date } = elem;
        const holidaySchedule = dayjs(date).startOf('day');
        const today = dayjs().startOf('day');

        // Compare only the dates, ignore the time
        const upcomingHolidays = holidaySchedule.isSameOrAfter(today);
        return upcomingHolidays;
      })
    : [];

  const hasUpcomingHolidayHours =
    Array.isArray(filteredHolidayHours) && filteredHolidayHours.length > 0;

  const { zeroIndexedHours, hasFullWeekData } = getZeroIndexedHours(hours);

  // Yext doesn't send back data for days that are closed
  let fullHoursArray = hasFullWeekData
    ? zeroIndexedHours
    : getCompletedHoursArray(zeroIndexedHours);

  /* If the store has holiday hours, loop through the holiday hours array and
   overwrite the values in fullHoursArray with the new holiday hours values */

  if (hasUpcomingHolidayHours) {
    filteredHolidayHours.reduce((acc, elem) => {
      const { date, hours } = elem;
      const dateValue = dayjs(date);
      const dayAsANumber = dateValue.day();

      if (dateIsWithinCurrentWeek(date)) {
        acc.splice(
          dayAsANumber,
          1,
          hours === '' ? `${dayAsANumber}:Closed:Closed` : `${dayAsANumber}:${hours}`
        );
      }
      return acc;
    }, fullHoursArray);
  }

  const copyOfHoursArray = [...fullHoursArray];
  const copyOfHoursArrayWithDateData = addDateDetailsToHoursArray(copyOfHoursArray as string[]);
  const { nextAvailableDay, openString, closedString, allRemainingStoresClosed, opensTomorrow } =
    getNextAvailableDay(copyOfHoursArrayWithDateData as CustomizedStoreHours[], locale as Locale);

  const storeClosedAllDays = !(copyOfHoursArrayWithDateData as CustomizedStoreHours[]).some(
    (elem) => elem.openHour !== 'Closed'
  );

  return {
    fullHoursArray: copyOfHoursArrayWithDateData,
    storeClosedAllDays,
    nextAvailableDay,
    openString,
    closedString,
    allRemainingStoresClosed,
    opensTomorrow,
  };
};

export const isStoreOpen = (storeHourData: StoreHourData, locale: Locale): boolean => {
  const { fullHoursArray } = convertStoreHourData(storeHourData, locale);
  const now = dayjs();
  const currentDay = now.day();
  const storeToUse = fullHoursArray[currentDay];
  return isDuringBusinessHours(storeToUse as CustomizedStoreHours);
};

function determineArrayToUse(fullWeek2DArray: CustomizedStoreHours[][]) {
  const [todayTilWeekend, weekStartTilToday] = fullWeek2DArray;
  const todayTilWeekendClosed = todayTilWeekend.every(
    (elem: CustomizedStoreHours) => elem.openHour === 'Closed' || !isDuringBusinessHours(elem)
  );
  if (todayTilWeekend.length === 1 && todayTilWeekendClosed) {
    return weekStartTilToday;
  } else {
    return todayTilWeekend;
  }
}

function allRemainingStoresClosedFn(hoursArray: CustomizedStoreHours[], day: number) {
  const copyOfHoursArray = [...hoursArray];
  copyOfHoursArray.splice(day, 1);
  const result = copyOfHoursArray.every((elem) => elem.openHour === 'Closed');
  return result;
}

function getNextAvailableDayResult(
  hoursObj: CustomizedStoreHours,
  hoursArray: CustomizedStoreHours[],
  locale: Locale
) {
  // Gives the current time as a dayjs object
  const currentTime = dayjs();
  const daysOfTheWeek: string[] = daysArray[locale];

  const { dayNumber, openHour, openMinute, closeHour, closeMinute, date } = hoursObj;

  // sets the next available day as a string (Sunday, Monday ... Saturday)
  const nextAvailableDay = daysOfTheWeek[parseInt(dayNumber)];
  const openString = `${openHour}:${openMinute}`;
  const closedString = `${closeHour}:${closeMinute}`;

  const allRemainingStoresClosed = allRemainingStoresClosedFn(hoursArray, +dayNumber);
  const nextAvailableDayDate = dayjs(date);
  const differenceInDaysByHours = Math.abs(nextAvailableDayDate.diff(currentTime, 'h'));
  const opensTomorrow = differenceInDaysByHours < 24;

  return {
    nextAvailableDay,
    openString,
    closedString,
    allRemainingStoresClosed,
    opensTomorrow,
  };
}

function getNextAvailableDay(hoursArray: CustomizedStoreHours[], locale: Locale) {
  let result: ConvertedStoreHoursType = {
    fullHoursArray: [],
    storeClosedAllDays: false,
    nextAvailableDay: '',
    openString: '',
    closedString: '',
    allRemainingStoresClosed: false,
    opensTomorrow: false,
  };
  // Gives the current day as a number (Sunday is 0, Monday is 1 ... Saturday is 6.)
  const currentDay = dayjs().day();

  const todayTilWeekend = hoursArray.slice(currentDay);
  const weekStartTilToday = hoursArray.slice(0, currentDay);
  const fullWeek2DArray = [todayTilWeekend, weekStartTilToday];

  const arrayToUse = determineArrayToUse(fullWeek2DArray);
  const nextAvailableDay = arrayToUse.find(
    (elem) => elem.openHour !== 'Closed' && isDuringBusinessHours(elem)
  );
  if (nextAvailableDay) {
    result = getNextAvailableDayResult(nextAvailableDay, hoursArray, locale);
  } else {
    const defaultNextAvailableDay = hoursArray.find((elem) => elem.openHour !== 'Closed');
    if (defaultNextAvailableDay) {
      result = getNextAvailableDayResult(defaultNextAvailableDay, hoursArray, locale);
    }
  }
  return result;
}

function getCompletedHoursArray(zeroIndexedHours: string[]) {
  const completedHours = [];
  for (let i = 0; i < 7; i++) {
    const day = zeroIndexedHours.find((hour: string) => hour.split(':')[0] === `${i}`);
    completedHours.push(day || `${i}:Closed:Closed`);
  }
  return completedHours;
}

function addDateDetailsToHoursArray(hoursArray: string[] | CustomizedStoreHours[]) {
  const currentDay = dayjs().day();
  const startOfWeek = dayjs().startOf('week');
  const beforeToday = startOfWeek.add(7, 'day');

  const todayTilWeekend = hoursArray.slice(currentDay);
  const weekStartTilToday = hoursArray.slice(0, currentDay);
  const combinedArray = [...weekStartTilToday, ...todayTilWeekend];

  combinedArray.forEach((hoursValue: string | CustomizedStoreHours, index) => {
    const [dayNumber, openHour, openMinute, closeHour, closeMinute] = (hoursValue as string).split(
      ':'
    );
    let date;
    if (index < currentDay) {
      date = dayjs(beforeToday).add(+dayNumber, 'day').format('MM, DD, YYYY');
    } else {
      date = dayjs(startOfWeek).add(+dayNumber, 'day').format('MM, DD, YYYY');
    }
    hoursArray[+dayNumber] = {
      dayNumber,
      openHour,
      openMinute,
      closeHour,
      closeMinute,
      date,
    };
  });
  return hoursArray;
}

function getZeroIndexedHours(hours: string) {
  const splitHours = hours?.split(',') || [];

  const zeroIndexedHours = splitHours.map((hour) => {
    const [day, ...rest] = hour.split(':');
    return `${+day - 1}:${rest.join(':')}`;
  });

  const hasFullWeekData = zeroIndexedHours.length === 7;

  return { zeroIndexedHours, hasFullWeekData };
}
