/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import React from 'react';
import styles from '../styles.module.scss';
import { useGeolocation } from '@/hooks/useGeoLocation';
import Location from '@/public/images/location.svg';
import { useLabel } from '@/hooks/useLabels';

interface UserCurrentLocationButtonProps {
  setValues: (
    values: React.SetStateAction<{
      locationInput: string;
      geoLocation: any;
    }>,
    shouldValidate?: boolean | undefined
  ) => void;
  setIsUserCurrentLocation: (isUserCurrentLocation: boolean) => void;
}

export function UserCurrentLocationButton({
  setValues,
  setIsUserCurrentLocation,
}: UserCurrentLocationButtonProps): React.ReactElement {
  const coordinates = useGeolocation();
  const useMyLocationAriaLabel = useLabel('label_Use_my_current_location');

  function handleCurrentLocationButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setIsUserCurrentLocation(true);

    if (coordinates) {
      const { latitude, longitude } = coordinates;
      setValues({
        locationInput: '',
        geoLocation: [latitude, longitude],
      });
    }
  }

  return (
    <Button
      type="submit"
      variant="contained"
      data="button-test"
      onClick={handleCurrentLocationButton}
      className={styles.searchButton}
      data-testid="address-search-based-on-location"
      ariaLabel={useMyLocationAriaLabel}
    >
      <Location alt="" className={styles.magnifyingGlass} />
    </Button>
  );
}
