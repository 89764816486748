/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Convert a 24 hours format string into a 12-hour format string
 *  @param hourIn24 - A string in 24-hour format. For example "14:00"
 *  @param  withAmPm - Whether the final string should include am or pm. Default is true
 *  @return A string in 12-hour format with am/pm. For example "2:00 pm"
 */
const convertToTwelveHourTime = (hourIn24: string, withAmPm: boolean = true): string => {
  const [hourToConvert, minuteToConvert] = hourIn24.split(':', 2);

  const twelveFormattedHour = Number(hourToConvert) % 12 || 12;
  let amOrPm;

  if (hourToConvert === '00') {
    amOrPm = 'am';
  } else if (hourToConvert === '24') {
    amOrPm = 'am';
  } else if (hourToConvert === '12') {
    amOrPm = 'pm';
  } else {
    amOrPm = Number(hourToConvert) >= 12 ? 'pm' : 'am';
  }
  return `${twelveFormattedHour}:${minuteToConvert} ${withAmPm && amOrPm}`;
};

/**
 * Tries to convert a string in 24-hour format into a word representation of that hour.
 * Example 12:00am into midnight
 * Returns the original string if conversion can not be done
 *  @param hourIn24 - A string in 24-hour format. For example "14:00"
 *  @return A string in 12-hour format with am/pm or a word representing that time"
 */

const hourRepresentationOfMidnight = ['12:00 am', '00:00 am', '12:00 AM', '00:00 AM'];

const tryConvertHourIntoWord = (hourIn24: string, midnightLabel: string): string => {
  if (hourRepresentationOfMidnight.includes(hourIn24)) {
    return midnightLabel.toLocaleLowerCase();
  } else {
    return hourIn24;
  }
};

export { convertToTwelveHourTime, tryConvertHourIntoWord };
