/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Hidden from '@/components/Hidden';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

interface StoreAddressProps {
  storeData: any;
  locIndex: number;
  address: any;
}

export const StoreAddress = ({ storeData, locIndex, address }: StoreAddressProps) => {
  const locale = useLocale();
  const isBR = locale === countryCodes.ptBr;
  const address2 =
    storeData.address2 !== 'NULL' && storeData.address2 !== undefined ? storeData.address2 : '';
  const addressLine = isBR
    ? `CEP ${storeData.zip}, ${storeData.city} ${storeData.isoRegionCode}`
    : `${storeData.city}, ${storeData.state}, ${storeData.zip}`;

  return (
    <div className={styles.address}>
      <Hidden mdDown>
        <p className={`${styles.addressLocationNumber} ${styles.marker}`}>{locIndex + 1}</p>
      </Hidden>
      <div
        id="store-address-container"
        data-testid="store-address-container"
        className={styles.remainderOfAddress}
      >
        <p className={azCommonStyles['az-body-1-regular']}>{address}</p>
        <br />
        {isBR && <p className={azCommonStyles['az-body-1-regular']}>{address2}</p>}
        <br />
        <p className={azCommonStyles['az-body-1-regular']}>{addressLine}</p>
      </div>
    </div>
  );
};
