/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import root from 'window-or-global';
import cx from 'classnames';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import usePrevious from '@/utils/usePrevious';
import { Grid } from '../Grid';
import Hidden from '../Hidden';
import { LoadingIndicatorDots } from '../LoadingIndicatorDots';
import { SmartLink as Link } from '../../utils/smartLink';
import FormikTextField from '../AZCustomComponent/FormGroup/TextField/formikTextField';
import { memoize } from '../../utils/memoize';
import azCommonStyles from '../../theme/globals.module.scss';
import { Store } from '../AZCustomComponent/Store';
import { Button } from '@/components/Button';
import StoreServicesComp from '../StoreServicesComp';
import { MyStore } from '../Store/MyStore/MyStore';
import Modal from '@/components/Modal';
import { daysArray } from '../../constants/date';
import { interpolateLabel } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { isEmptyObject } from '../../utils/common';
import type { GeoData, SubmitSearchValues, PrevStoreObject } from './types';
import StoreMap from './StoreMap';
import ShowMore from './ShowMore';
import styles from './styles.module.scss';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import { pageTypeSiteSection } from '@/utils/analytics/dataLayer/pageTypeSiteSection';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { countryNames } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { UserCurrentLocationButton } from './UserCurrentLocationButton';
import type { StoreDetails, UTagData } from '@/types';
import { useYextStoreHours } from '../AZCustomComponent/Store/useStoreHours';
import { useFeatureFlag } from '@/features/globalConfig';
import { type SearchStoreMutationOptions, type YextStore } from '@/features/fulfillment';
import { useSearchStoreMutation } from '@/features/fulfillment/api/getSearchStore';
import NextImage from '@/components/NextImage';
import { countryCodes } from '@/constants/locale';

export const labelMap = {
  input: 'label_Footer_Modal_EnterCityStateOrZipCode',
  open: 'label_Footer_modal_Open',
  until: 'label_Footer_modal_Until',
  closed: 'label.header.myLocalStore.CLOSED',
  today: 'label_checkout_bopus_today',
  Hours: 'label_Footer_modal_24Hours',
  tomorrow: 'label_Footer_modal_Tomorrow',
  mile: 'label_MyAccountMyProfile_vehicle_Miles',
  km: 'label_StoreLocator_modal_Km',
  instructions: 'label_Footer_modal_SearchAndSetAStoreToSeeProductPricesAndDealsNearYou',
  lblSearchForAStore: 'label_Footer_modal_SearchForAStore',
  lblStoresFound: 'label_Store_modal_StoresFound',
  lblClearSearch: 'label_Footer_modal_ClearSearch',
  lblList: 'label_Footer_modal_List',
  lblMap: 'label_Footer_modal_Map',
  lblNearYou: 'label_StoreLocator_nearYou',
  lblBackToStoreList: 'label_StoreLocator_modal_BackToStoreList',
  lblStore: 'label_StoreLocator_modal_Store',
  lblStoreHours: 'label_Footer_modal_StoreHours',
  lblZipCodeMustBe5Digits: 'error_RewardsSignUp_body_ZipCodeMustBe5Digits',
  lblZipCodeBrazil: 'error_Brazil_ZipCodeFormat',
  lblFindAnyStoreThatMatchedYourSearchCriteriaPleaseTryAgain:
    'label_StoreLocator_modal_SorryWeCouldnTFindAnyStoreThatMatchedYourSearchCriteriaPleaseTryAgain',
  lblCouldntFindUS:
    'label_Footer_modal_WeCouldnTFindAnyStoresWithin150MilesOfYourSearchPleaseTryAgainOrBrowseAllStoreLocationsInOur',
  lblCouldntFindMX: 'label_Footer_modal_WeDidNotFindAnyStoresWithin240km',
  label_Footer_modal_OnThisPage: 'label_Footer_modal_OnThisPage',
  label_StoreLocator_modal_AllLocations: 'label_StoreLocator_modal_AllLocations',
  lblPlease_remove_invalid_characters: 'label_Please_remove_invalid_characters',
  lblMonday: 'label_StoreLocator_modal_Monday',
  lblTuesday: 'label_StoreLocator_modal_Tuesday',
  lblWednesday: 'label_StoreLocator_modal_Wednesday',
  lblThursday: 'label_StoreLocator_modal_Thursday',
  lblFriday: 'label_StoreLocator_modal_Friday',
  lblSaturday: 'label_StoreLocator_modal_Saturday',
  lblSunday: 'label_StoreLocator_modal_Sunday',
  lblRequired: 'label_StoreLocator_modal_Required',
  lblShowingCount: 'label_Showing',
  lblStoreDetails: 'Label_Store_Details',
  lblOpenUntil: 'label_checkout_bopus_openUntil',
  lblClosedOpen: 'label_CLOSED-open_at',
  label_StoreLocator_modal_StoreDirectory: 'label_StoreLocator_modal_StoreDirectory',
  label_store_search_locations: 'label_store_search_locations',
  Label_storeLocator_NoResultsFoundPleaseEnterAValidUSZipCode:
    'Label_storeLocator_NoResultsFoundPleaseEnterAValidUSZipCode',
  locationError: 'label_location_error',
  lblOf: 'label_checkout_shipping_Of',
  lblShowMore: 'label_Show_More',
  lblLocation: 'label_ShelfPage_leftSideBar_Location',
  lblSubmitAriaLabel: 'label_Submit_Search',
};

type Props = {
  handleClose: () => void;
  closeAllModals?: () => void;
  setStore: (
    storeNumber: string,
    zipCode: string,
    isNearByStoreSelected: boolean,
    searchedInput?: string
  ) => void;
  storeDetails?: StoreDetails;
  prevStore?: PrevStoreObject;
};

function StoreLocator({ handleClose, setStore, closeAllModals, prevStore }: Props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    data: storeData,
    status: searchStatus,
    mutate: searchStore,
    reset: clearSearchResult,
  } = useSearchStoreMutation();
  const initialSearchScreen = storeData === undefined;
  let moreDetailsStyles = '';
  let headerContent = null;
  let renderTab = null;
  let selected = '';
  let myStore = null;
  let renderStoreServices = null;
  let renderMapOnSpecific = null;
  let showBackBtn = false;
  const defaultNumOfStoresToSliceOut = 10;
  const locale = useLocale();
  const daysOfTheWeek = daysArray[locale];
  const labels = useLabels(labelMap);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isUserCurrentLocation, setIsUserCurrentLocation] = useState(false);
  const [mapWidth, setMapWidth] = useState<number>();
  const [modalHeight, setModalHeight] = useState<number>();
  const [moreDetails, setMoreDetails] = useState(null);
  const [numOfStoresToSliceOut, setNumOfStoresToSliceOut] = useState(defaultNumOfStoresToSliceOut);
  const [searchedInput, setSearchedInput] = useState('');
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(-1);
  const [selectedStore, setSelectedStore] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState('list');
  const [storeLocations, setStoreLocations] = useState<YextStore[]>([]);
  const [mapWrapper, setMapWrapper] = useState<any>();
  const [searching, setSearching] = useState(false);
  const [getRefIndex, setGetRefIndex] = useState(0);

  const rrMapAzrmobEnabled = useFeatureFlag('RR_MAP_AZRMOB_ENABLED') === 'true';
  const { isUtagReady: isUtagDefined } = useUtagReady();
  const container = useRef<HTMLDivElement | null>(null);
  const mobileTabs = useRef<HTMLDivElement | null>(null);
  const search = useRef<HTMLDivElement | null>(null);
  const storeInfoRef = useRef<HTMLDivElement | null>(null);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [focusMoreDetailsBtn, setfocusMoreDetailsBtn] = useState('');
  const isBrazil = locale === countryCodes.ptBr;

  const prevNumOfStoresToSliceOut =
    usePrevious(numOfStoresToSliceOut) || defaultNumOfStoresToSliceOut;

  useEffect(() => {
    const tempStoreLocations = storeData?.response?.locations;
    const slicedStoreLocations = tempStoreLocations?.slice(0, numOfStoresToSliceOut);
    if (
      slicedStoreLocations !== undefined &&
      slicedStoreLocations.length > defaultNumOfStoresToSliceOut
    ) {
      setGetRefIndex(prevNumOfStoresToSliceOut);
    }

    if (tempStoreLocations) {
      setStoreLocations(slicedStoreLocations ?? []);
    }

    if (searching && storeData !== undefined) {
      setSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, numOfStoresToSliceOut]);

  function originalUtagDataBeforeManipulation(obj: Partial<UTagData> | undefined) {
    if (!obj) {
      return {};
    }
    return JSON.parse(JSON.stringify(obj));
  }

  useEffect(() => {
    setHeights();
    const rootUtagData = root?.utag_data ?? {};
    const originalUtagData = originalUtagDataBeforeManipulation(window.utag_data);
    pageTypeSiteSection('mystorelocator'); // TODO This analytic block looks questionable
    // May need some work when the map feature is turned back on

    if (isUtagDefined && rootUtagData) {
      const { utag_data } = root; // redefines root.utag_data after loading google map
      if (root.utag_data === undefined) {
        root.utag_data = utag_data;
      }
    }
    window.utag_data = originalUtagDataBeforeManipulation(originalUtagData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (moreDetails) {
      const selectedStoreIndex = storeLocations.findIndex((obj) => obj.id == moreDetails);
      setSelectedLocationIndex(selectedStoreIndex);
      setSelectedStore(storeLocations[selectedStoreIndex]);
    } else {
      setSelectedStore({});
      setMoreDetails(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreDetails]);

  useEffect(() => {
    if (storeData?.response?.locations.length === 0) {
      clickTrack({
        eventType: 'StoreNullSearch',
        storeSearchTerm: searchedInput,
      });
    }
  }, [storeData]); // eslint-disable-line

  const setDetails = (storeId: any) => {
    setMoreDetails(storeId);
    setfocusMoreDetailsBtn(storeId);
  };

  const handleBack = () => {
    setMoreDetails(null);
  };

  const setTab = memoize((selectedTab: any) => () => {
    setHeights();
    setSelectedTab(selectedTab);
  });

  const setHeights = () => {
    const modal: any = container;
    const tempModalHeight: number = isMobile
      ? window.innerHeight
      : Math.min(674, Number(modal?.current?.offsetHeight));

    if (Number.isNaN(tempModalHeight)) {
      return;
    }

    setMapWrapper(mapContainer);
    getMapWidth(mapWrapper, isMobile);

    if (tempModalHeight !== modalHeight || !mapWidth || containerHeight !== containerHeight) {
      setModalHeight(tempModalHeight);
      setContainerHeight(containerHeight);
    }
  };

  const getMapWidth = (mapWrapper: any, isMobile: boolean) => {
    if (mapWrapper?.current) {
      if (isMobile) {
        const mapWrapperClientWidth = mapWrapper?.current?.parentElement?.clientWidth;
        setMapWidth(mapWrapperClientWidth);
      } else {
        const mapWrapperClientWidth = mapWrapper?.current?.clientWidth;
        setMapWidth(mapWrapperClientWidth - 10);
      }
    }
  };

  const storesCountryCodes = {
    [countryCodes.us]: 'US',
    [countryCodes.mx]: 'MX',
    [countryCodes.ptBr]: 'BR',
  } as const;

  const submitSearch = (values: SubmitSearchValues) => {
    const country = countryNames[locale] || null;
    const countryCode = storesCountryCodes[locale];
    setSearching(true);

    const params: SearchStoreMutationOptions = {
      location: [],
      country: country || '',
      countryCode,
      includeFilters: true,
    };
    // TODO: refactor this if condition with relation to isUserCurrentLocation
    if (!isUserCurrentLocation) {
      if (searching) {
        return;
      }
      setNumOfStoresToSliceOut(defaultNumOfStoresToSliceOut);
      setSearchedInput(values.locationInput);
      params.location.push(values.locationInput);

      if (country === countryNames['es-MX'] || country === countryNames['pt-BR']) {
        params.location.push(country);
      }
    } else {
      params.location.push(...values.geoLocation);
    }

    searchStore(params);
  };

  const clear = (clearFormik: () => void) => {
    clearFormik();
    clearSearchResult();
    setStoreLocations([]);
    setSearchedInput('');
    setMoreDetails(null);
    setNumOfStoresToSliceOut(defaultNumOfStoresToSliceOut);
  };

  const getCustomClassStyleForMapOrList = (mapOrList: string) => {
    let selected = '';

    if (selectedTab === mapOrList) {
      selected = styles.selected;
    }

    return selected;
  };

  const renderMapAndListButtons = () => {
    const numOfStores = storeData?.response?.locations.length ?? 0;

    if (numOfStores > 0) {
      return (
        <div ref={mobileTabs} className={styles.mobileTabs}>
          <Button customClass={getCustomClassStyleForMapOrList('list')} onClick={setTab('list')}>
            {labels.lblList}
          </Button>
          <Button customClass={getCustomClassStyleForMapOrList('map')} onClick={setTab('map')}>
            {labels.lblMap}
          </Button>
        </div>
      );
    }

    return null;
  };

  const renderTabs = () => {
    const response = storeData?.response;
    const enabled = null;

    if (!isMobile || !response || !rrMapAzrmobEnabled) {
      return enabled;
    }

    return renderMapAndListButtons();
  };

  const renderSearchInfo = (resetForm: () => void, isError: boolean) => {
    const numOfStores = storeData?.response?.locations.length || 0;
    const storeResponse = storeData?.response;
    const searchInfoPadding = isError ? styles.searchInfoError : '';
    const storesFoundBrazil = interpolateLabel(labels.lblStoresFound, {
      numOfStores,
    });
    const storesFoundCommon = isUserCurrentLocation
      ? `${numOfStores} ${labels.lblStoresFound} ${labels.lblNearYou}`
      : `${numOfStores} ${labels.lblStoresFound}`;

    const storesFound = locale === countryCodes.ptBr ? storesFoundBrazil : storesFoundCommon;

    if (!storeResponse) {
      return null;
    }

    return (
      <>
        {numOfStores > 0 ? (
          <Grid container alignItems="center" className={cx(styles.searchInfo, searchInfoPadding)}>
            <Grid
              id="search-for-store-found-stores-text"
              data-testid="search-for-store-found-stores-text"
              item
              xs={7}
              className={cx(azCommonStyles['az-body-2-regular'], styles.pinText)}
            >
              {storesFound}
            </Grid>
            <Grid item xs={5} className={styles.clearTextRight}>
              <Link
                id="clearTextLink"
                onClick={() => clear(resetForm)}
                to="#"
                className={cx(azCommonStyles['az-body-2-regular'], styles.clearText)}
                role="button"
              >
                {labels.lblClearSearch}
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" className={cx(styles.searchInfo, searchInfoPadding)}>
            <p
              className={cx(
                styles.storeLocatorNoLocationsText,
                azCommonStyles['az-body-2-regular']
              )}
            >
              {numOfStores} {labels.label_store_search_locations}
            </p>
          </Grid>
        )}
      </>
    );
  };

  const renderSearchBtn = () => (
    <Button
      type="submit"
      variant="contained"
      className={styles.searchButton}
      onClick={(e) => {
        e.stopPropagation();
        setIsUserCurrentLocation(false);
      }}
      data-testid="address-search-keyword"
      ariaLabel={labels.lblSubmitAriaLabel}
    >
      {searchStatus !== 'loading' ? (
        <NextImage
          src="/images/magnifying_glass.svg"
          alt=""
          className={styles.magnifyingGlass}
          width={21}
          height={21}
        />
      ) : (
        <LoadingIndicatorDots size={7} color="#fff" />
      )}
    </Button>
  );

  const renderMapInstructions = (isError: boolean) => {
    const storeResponse = storeData?.response;
    const topPadding = isError ? styles.errorPadTop : '';

    if (!storeResponse) {
      return (
        <div
          id="my-store-instructions"
          data-testid="my-store-instructions"
          className={cx(
            azCommonStyles['az-padding-right-4xs'],
            azCommonStyles['az-body-2-regular'],
            styles.instructions,
            topPadding
          )}
        >
          {labels.instructions}
        </div>
      );
    }

    return '';
  };

  const renderStoreServicesComp = (initialSearchScreen: boolean) => {
    if (initialSearchScreen) {
      return null;
    }

    return (
      <Hidden mdUp>
        <div className={styles.storeServeComp}>
          <StoreServicesComp handleClose={closeAllModals || handleClose} />
        </div>
      </Hidden>
    );
  };

  const renderFormSearchInput = (values: any) => {
    const geoData: GeoData = {
      isUserCurrentLocation,
      hasData: values?.geoLocation && !!values?.geoLocation.length,
    };
    return (
      <Field
        id="SearchInput"
        name="locationInput"
        placeholder={labels.input}
        component={FormikTextField}
        validate={(value: any) => validateSearch(value, labels, locale, geoData)}
        noMessage
        autoComplete="on"
      />
    );
  };

  const renderStoreInfo = (storeLocations: Array<YextStore>) => {
    const theLocationDistances = storeData?.response?.locationDistances;
    const checkRenderStoreInfo = storeLocations && moreDetails === null;
    let storeInfo = null;

    if (storeData !== undefined && checkRenderStoreInfo) {
      storeInfo = storeLocations.map((store, index) => (
        <Store
          searchedInput={searchedInput}
          isGetRef={0 < getRefIndex && index === getRefIndex ? true : false}
          storeData={store}
          setStore={setStore}
          setMoreDetails={setDetails}
          selectedStore={selectedLocationIndex}
          key={store.id}
          locIndex={index}
          address={store.address}
          fromLat={store.yextDisplayLat}
          fromLng={store.yextDisplayLng}
          phone={store.phone}
          storeId={store.id}
          locationDistances={theLocationDistances}
          nearByStores={false}
          focusMoreDetailsBtn={focusMoreDetailsBtn}
          prevStore={prevStore}
        />
      ));
    }

    return storeInfo;
  };

  const {
    storeFullHours,
    today: { isOpenNow, open24Hours },
  } = useYextStoreHours(selectedStore);

  const makeStoreDetailsData = (): StoreDetails => {
    const date = new Date();
    const today = daysOfTheWeek[date.getDay()];

    return {
      active: true,
      address1: selectedStore.address,
      address2: '',
      bopusEnabled: false,
      bopusTransactionId: null,
      city: selectedStore.city,
      currentDay: today,
      currentTimeZoneDateTime: '',
      estPickUpDate: null,
      latitude: selectedStore.yextDisplayLat,
      longitude: selectedStore.yextDisplayLng,
      messageToDisplay: '',
      nextTransitionDateTime: null,
      open24Hours,
      openedNow: isOpenNow,
      phoneNumber: selectedStore.phone,
      state: selectedStore.state,
      storeFullHours: storeFullHours,
      storeNumber: selectedStore.id,
      storeOrderedDate: null,
      storeOrderNumber: null,
      zip: selectedStore.zip,
    };
  };

  const showTitle = (initialSearchScreen: boolean) => {
    if (isMobile && moreDetails === null) {
      return <span>{labels.lblSearchForAStore}</span>;
    } else if (!isMobile && moreDetails === null) {
      return false;
    } else if (initialSearchScreen) {
      return false;
    } else if (moreDetails !== null) {
      return false;
    } else {
      return <span>{labels.lblSearchForAStore}</span>;
    }
  };

  const theBody = (
    moreDetailsStyles: any,
    headerContent: any,
    renderTab: any,
    selected: any,
    myStore: any,
    renderStoreServices: any,
    initialSearchScreen: any,
    renderMapOnSpecific: any
  ) => {
    return (
      <>
        <div className={styles.container} ref={container}>
          <div className={moreDetailsStyles}>
            <Hidden mdDown>
              <div
                className={
                  isBrazil
                    ? styles.storeHeaderTitleContainerBrazil
                    : styles.storeHeaderTitleContainer
                }
              >
                <h1
                  className={cx({
                    [azCommonStyles['az-title-5-medium']]: true,
                    [styles.storeHeaderTitle]: true,
                    [styles.storeHeaderTitleBrazil]: isBrazil,
                  })}
                  id="at_search_for_store_header"
                >
                  {labels.lblSearchForAStore}
                </h1>
              </div>
            </Hidden>
            <div className={styles.headerContent}>{headerContent}</div>
            {renderTab}
            <div ref={storeInfoRef} className={cx(styles.list, selected)}>
              {myStore}
              <ul>{renderStoreInfo(storeLocations)}</ul>
              <ShowMore
                moreDetails={moreDetails}
                initialSearchScreen={initialSearchScreen}
                amountOfShownLocations={storeLocations.length}
                totalAmountOfLocations={storeData?.response?.locations.length ?? 0}
                labelMap={labels}
                defaultNumOfStoresToSliceOut={defaultNumOfStoresToSliceOut}
                numOfStoresToSliceOut={numOfStoresToSliceOut}
                setNumOfStoresToSliceOut={(value) => setNumOfStoresToSliceOut(value)}
              />
            </div>
          </div>
          {renderMapOnSpecific}
        </div>
      </>
    );
  };

  if (moreDetails) {
    moreDetailsStyles = cx(
      styles.searchContainerMoreDetails,
      styles.searchContainerMoreDetailsList
    );
    renderStoreServices = renderStoreServicesComp(initialSearchScreen);
  } else {
    moreDetailsStyles = styles.searchContainer;
    headerContent = (
      <div className={cx({ [styles.search]: true, [styles.searchBrazil]: isBrazil })} ref={search}>
        <Formik
          initialValues={{
            locationInput: '',
            geoLocation: [],
          }}
          onSubmit={submitSearch}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ setValues, resetForm, values, errors }) => (
            <>
              <Form action="#">
                <div className={styles.inputContainer}>
                  <div className={styles.storeInputForm}>
                    {renderFormSearchInput(values)}
                    {renderSearchBtn()}
                    <UserCurrentLocationButton
                      setValues={setValues}
                      setIsUserCurrentLocation={setIsUserCurrentLocation}
                    />
                  </div>
                  {errors.locationInput && !searching && (
                    <p role="alert" className={styles.errorMessage}>
                      {errors.locationInput}
                    </p>
                  )}
                </div>
              </Form>
              {renderMapInstructions(!!errors.locationInput)}
              <div className={styles.widthFix} />
              {renderSearchInfo(resetForm, !!errors.locationInput)}
            </>
          )}
        </Formik>
      </div>
    );
    renderTab = renderTabs();
    renderMapOnSpecific = (
      <StoreMap
        initialSearchScreen={initialSearchScreen}
        selectedTab={selectedTab}
        mapContainer={mapContainer}
        storeData={storeData}
      />
    );
  }

  if (selectedTab === 'list') {
    selected = styles.selected;
  }

  if (!isEmptyObject(selectedStore) && !initialSearchScreen) {
    const storeDetails = makeStoreDetailsData();
    myStore = (
      <MyStore
        closeStoreModal={handleClose}
        backBtnHandler={handleBack}
        storeDetails={storeDetails}
        storeHours={{
          holidayHours: selectedStore.holidayHours,
          hours: selectedStore.hours,
          locale,
        }}
        storeDetailsLabel={labels.lblStoreDetails}
        setStore={setStore}
        moreDetailsLocIndex={selectedLocationIndex}
        moreDetailsStoreSelectedID={selectedStore.id}
        moreDetailsStoreSelectedZip={selectedStore.zip}
      />
    );
    showBackBtn = true;
  }

  const ariaLabelledBy = isMobile ? 'modal-title' : 'at_search_for_store_header';

  return (
    <Modal
      role="dialog"
      ariaLabelledBy={ariaLabelledBy}
      ariaModal={true}
      desktopSize={'large'}
      tabletSize={'fullscreen'}
      noGap={true}
      showBackButton={showBackBtn}
      showCloseButton
      title={showTitle(initialSearchScreen)}
      handleClose={handleClose}
      handleBack={handleBack}
      isOpen={true}
      body={theBody(
        moreDetailsStyles,
        headerContent,
        renderTab,
        selected,
        myStore,
        renderStoreServices,
        initialSearchScreen,
        renderMapOnSpecific
      )}
    />
  );
}

const STOREZIP = /^(?:\d){5}$/;
const STOREZIPBRAZIL = /^\d{5}-?\d{3}$/;
const STOREZIPSEARCH = /^(?:\d+)$/; // Need to change STORESPECIALCHAR validation after geolocation search is added

const STORESPECIALCHAR = /[!@#$%^&*()+=[\]{};':"\\|<>/?]/;
const ONLYDIGITS = /[0-9]/;
const ONLYAPLHABETS = /[A-Za-z]/;
const ONLYSPACES = /^\s+$/;

export function validateSearch(value: string, label: any, locale: any, geoData: GeoData): string {
  let storeError = '';
  const isBR = locale === countryCodes.ptBr;
  const storeZIP = isBR ? STOREZIPBRAZIL : STOREZIP;

  if (geoData.isUserCurrentLocation) {
    if (!geoData.hasData) {
      storeError = label.locationError;
    }

    return storeError;
  }

  if (!value) {
    storeError = label.lblRequired;
  } else {
    // Special characters allowed: - , .
    if (STORESPECIALCHAR.test(value) || ONLYSPACES.test(value)) {
      storeError = label.lblPlease_remove_invalid_characters;
    } else if (
      ONLYDIGITS.test(value) &&
      !ONLYAPLHABETS.test(value) &&
      (!storeZIP.test(value) || (!isBR && !STOREZIPSEARCH.test(value)))
    ) {
      // Test to have strictly 5 digits for the zipcode
      storeError = isBR ? label.lblZipCodeBrazil : label.lblZipCodeMustBe5Digits;
    }
  }
  return storeError;
}

export default StoreLocator;
