/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import Hidden from '../../Hidden';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const mapReplacementImg = {
  imgAlt: 'AutoZone',
  srcRoot: '/images/',
  srcUS: 'US',
  srcMX: 'MX',
  srcBase: '_map_replacement.png',
};

type Props = {
  moreDetails: any;
  initialSearchScreen: boolean;
  amountOfShownLocations: number;
  totalAmountOfLocations: number;
  labelMap: any;
  defaultNumOfStoresToSliceOut: number;
  numOfStoresToSliceOut: number;
  setNumOfStoresToSliceOut: (a: number) => void;
};

const ShowMore = ({
  moreDetails,
  initialSearchScreen,
  amountOfShownLocations,
  totalAmountOfLocations,
  labelMap,
  defaultNumOfStoresToSliceOut,
  numOfStoresToSliceOut,
  setNumOfStoresToSliceOut,
}: Props) => {
  const locale = useLocale();
  const { imgAlt, srcRoot, srcUS, srcMX, srcBase } = mapReplacementImg;
  const isMxWeb = locale === countryCodes.mx;

  if ((moreDetails || !initialSearchScreen) && !moreDetails) {
    if (totalAmountOfLocations > 0) {
      return (
        <div
          className={cx(
            azCommonStyles['az-title-5-medium'],
            styles.showMoreSection,
            styles.showingNumberSection
          )}
        >
          {labelMap.lblShowingCount} {amountOfShownLocations} {labelMap.lblOf}{' '}
          {totalAmountOfLocations}
          {amountOfShownLocations !== totalAmountOfLocations && (
            <Button
              variant="link"
              customClass={styles.showMoreBtn}
              onClick={() => {
                let incrementValue = defaultNumOfStoresToSliceOut;

                if (numOfStoresToSliceOut + defaultNumOfStoresToSliceOut > totalAmountOfLocations) {
                  incrementValue = totalAmountOfLocations - numOfStoresToSliceOut;
                }

                setNumOfStoresToSliceOut(numOfStoresToSliceOut + incrementValue);
              }}
            >
              {labelMap.lblShowMore}
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className={styles.noStoresContainer}>
        {!isMxWeb ? (
          <p className={cx(styles.storeLocatorBrowse, azCommonStyles['az-body-2-regular'])}>
            {labelMap.lblCouldntFindUS}{' '}
            <a
              href="https://autozone.com/locations/"
              className={azCommonStyles['az-body-2-link']}
              rel="noopener noreferrer"
              aria-label="AutoZone Store Locator"
            >
              {labelMap.label_StoreLocator_modal_StoreDirectory}
            </a>
          </p>
        ) : (
          <p className={cx(styles.storeLocatorBrowse, azCommonStyles['az-body-2-regular'])}>
            {labelMap.lblCouldntFindMX}{' '}
            <a
              href="https://autozone.com.mx/ubicaciones/"
              className={azCommonStyles['az-body-2-link']}
              rel="noopener noreferrer"
              aria-label="AutoZone Store Locator"
            >
              {labelMap.label_StoreLocator_modal_AllLocations}
            </a>{' '}
            {labelMap.label_Footer_modal_OnThisPage}
          </p>
        )}
        <Hidden lgUp>
          <img
            alt={`${imgAlt} `}
            src={`${srcRoot}${isMxWeb ? srcMX : srcUS}${srcBase}`}
            className={styles.searchImage}
          />
        </Hidden>
      </div>
    );
  }

  return null;
};

export default ShowMore;
