/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import type { StoreInformation } from '../../StoreLocator/types';
import { tryConvertHourIntoWord } from '../../StoreLocator/datalayer/dateTimeTransformers';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import {
  getCurrentWeek,
  translateDayToEnglish,
  translateDayToSpanish,
} from '@/components/AZCustomComponent/Store/helpers';
import { useFeatureFlag } from '@/features/globalConfig';
import { countryCodes } from '@/constants/locale';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

type Day = {
  day: string;
  information: StoreInformation;
  currentDayStyle: string;
};

const labelMap = {
  open24Hours: 'label_Homepage_header_Open24hours',
  closed: 'label_StoreLocator_modal_Closed',
  holidayHours: 'label_StoreLocator_holidayHours',
  midnight: 'label_StoreLocator_midnight',
};

interface StoreWeekDays {
  [day: string]: any;
}

const createOrderedWeek = (
  storeWeekDays: StoreWeekDays,
  locale: Locale,
  storeDetailsEnglishOnlyKeysEnabled: boolean
): Day[] => {
  const orderedWeek: Day[] = [];
  if (!storeWeekDays || Object.keys(storeWeekDays).length === 0) {
    return orderedWeek;
  }

  const currentWeek = getCurrentWeek(storeDetailsEnglishOnlyKeysEnabled ? countryCodes.us : locale);

  currentWeek.forEach((day: string) => {
    /* If the data comes from ATG and STORE_DETAILS_DAYS_ENGLISH_ONLY is off day key
    can be in english or spanish
     */
    let daySchedule;
    let dayLabel = day;

    //TODO remove this condition we can do without STORE_DETAILS_DAYS_ENGLISH_ONLY flag
    if (locale === countryCodes.mx && storeDetailsEnglishOnlyKeysEnabled) {
      dayLabel = translateDayToSpanish(day);
    }

    if (day in storeWeekDays) {
      daySchedule = storeWeekDays[day];
    }
    //TODO remove this condition we can do without STORE_DETAILS_DAYS_ENGLISH_ONLY flag
    else if (translateDayToSpanish(day) in storeWeekDays) {
      dayLabel = dayLabel.charAt(0).toUpperCase() + dayLabel.slice(1);
      daySchedule = storeWeekDays[dayLabel];
    }
    //TODO remove this condition we can do without STORE_DETAILS_DAYS_ENGLISH_ONLY flag
    else if (translateDayToEnglish(day) in storeWeekDays) {
      if (locale === countryCodes.ptBr) {
        dayLabel = dayLabel.charAt(0).toUpperCase() + dayLabel.slice(1);
      }
      daySchedule = storeWeekDays[translateDayToEnglish(day)];
    }

    orderedWeek.push({
      day: dayLabel,
      currentDayStyle: `${styles.nonCurrentDay}`,
      information: daySchedule,
    });
  });

  const [currentDay] = orderedWeek;

  orderedWeek.splice(0, 1, {
    ...currentDay,
    currentDayStyle: currentDay.information.isHolidayHours
      ? styles.currentDayHoliday
      : styles.currentDay,
  });

  return orderedWeek;
};

//TODO: Add type to storeDetails
const StoreHours = ({ storeDetails }: { storeDetails: any }) => {
  const storeDetailsEnglishOnlyKeysEnabled =
    useFeatureFlag('STORE_DETAILS_DAYS_ENGLISH_ONLY') === 'true';

  const locale = useLocale();
  const isBR = locale == countryCodes.ptBr;
  const labels = useLabels(labelMap);
  const decodeHtmlCharacters = (str: string) =>
    str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

  const displayStoreHours = (information: StoreInformation) => {
    let { open, close, closed, open24Hours, isHolidayHours } = information;

    if ((open24Hours || (close === '0:00 pm' && open === '0:00 am')) && !isHolidayHours) {
      return labels.open24Hours;
    }

    // A store is marked as closed when is not sent in the array of hours in YEXT.
    // synthetic representation added by the front-end has the closed property.
    if (closed && !open) {
      return labels.closed;
    }

    if (closed && open && open.includes('undefined')) {
      return labels.closed;
    }
    if (closed && open && !open.includes('undefined')) {
      return `${decodeHtmlCharacters(open)} - ${decodeHtmlCharacters(close)}`;
    }

    // A store is marked as closed  in ATG when the open and close hours are the same
    // This is consistent with how it is done in the legacy site.
    else if (!closed && !open24Hours && open === close) {
      return labels.closed;
    }

    if (!open.includes('NaN:undefined')) {
      if (isBR) {
        dayjs.extend(customParseFormat);
        open = dayjs(open, 'H:mm').format('HH:mm');
        close = dayjs(close, 'H:mm').format('HH:mm');
      }
      const openLabel = tryConvertHourIntoWord(open, labels.midnight);
      const closedLabel = tryConvertHourIntoWord(close, labels.midnight);
      return `${openLabel} - ${closedLabel}`;
    }
  };

  return (
    <ul>
      {createOrderedWeek(
        storeDetails?.storeFullHours,
        locale,
        storeDetailsEnglishOnlyKeysEnabled
      ).map(({ day, information, currentDayStyle }) => (
        <li
          key={day}
          className={`${styles.hourListItem}  ${currentDayStyle}
               ${information.isHolidayHours ? styles.hasHolidayHours : ''}`}
        >
          <div
            className={cx(
              azCommonStyles['az-body-2-regular'],
              azCommonStyles['az-padding-top-4xs'],
              azCommonStyles['az-padding-bottom-4xs'],
              styles.leftWidth,
              styles.daysContainer,
              azCommonStyles['s6'],
              azCommonStyles['m6'],
              azCommonStyles['left'],
              azCommonStyles['left-align'],
              styles.dayLabel
            )}
            tabIndex={0}
          >
            {decodeHtmlCharacters(day)}
            <br></br>
            {information.isHolidayHours ? `(${labels.holidayHours})` : ''}
          </div>
          <div
            className={cx(
              azCommonStyles['az-body-2-regular'],
              azCommonStyles['az-padding-top-4xs'],
              azCommonStyles['az-padding-bottom-4xs'],
              styles.rightWidth,
              styles.timeContainer,
              azCommonStyles['s6'],
              azCommonStyles['m6'],
              azCommonStyles['right'],
              azCommonStyles['right-align'],
              azCommonStyles['az-subtitle-2-medium'],
              styles.timeLabel,
              information.isHolidayHours && information.open.includes('undefined')
                ? styles.closedHolidayHours
                : information.isHolidayHours
                ? styles.openHolidayHours
                : ''
            )}
            tabIndex={0}
          >
            {displayStoreHours(information)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default StoreHours;
