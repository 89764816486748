/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const interpolateLabel = (
  label = '',
  data: Record<string, unknown>,
  replaceAll = false
): string => {
  let result = label;

  for (const key in data) {
    result = replaceAll
      ? result.split(`\${${key}}`).join(String(data[key]))
      : result.replace(`\${${key}}`, String(data[key]));
  }

  return result;
};
