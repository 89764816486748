/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLabel } from '@/hooks/useLabels';
import styles from './styles.module.scss';

interface StoreNumberProps {
  storeId: string;
}

export const StoreNumber = ({ storeId }: StoreNumberProps) => {
  const label = useLabel('store_number');

  return (
    <div className={styles.storeLocatorStoreNumber}>
      {`${label}`}
      {storeId}
    </div>
  );
};
