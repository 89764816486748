/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import StoreLocator from '../../StoreLocator';
import { useSetStore } from '@/features/fulfillment/hooks/useSetStore';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import usePrevious from '@/utils/usePrevious';

export type Props = {
  closeAllModals?: () => void;
  closeStoreModal: () => void;
  ariaLabelledBy?: string;
};

export const SearchStoreModal = ({ closeStoreModal, closeAllModals }: Props) => {
  const setStore = useSetStore(closeStoreModal);
  const { data: storeDetails } = useStoreDetailsData();
  const prevStoreNumber = storeDetails?.storeNumber;
  const prevAddress1 = storeDetails?.address1;
  const prevStoreObject = { prevStoreNumber, prevAddress1 };
  const prevStore = usePrevious(prevStoreObject);

  const handleModalCloseAllModals = () => {
    closeStoreModal();
    if (closeAllModals) {
      closeAllModals();
    }
  };

  return (
    <StoreLocator
      storeDetails={storeDetails}
      setStore={setStore}
      handleClose={closeStoreModal}
      closeAllModals={handleModalCloseAllModals}
      prevStore={prevStore}
    />
  );
};
