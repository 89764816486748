/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const capitalizePhrase = (value?: string | null): string => {
  return value
    ? value
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word[0]?.toUpperCase() + word.substring(1);
        })
        .join(' ')
    : '';
};
