/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import { commonValidation } from '@/constants/validation';
import type { Locale } from '@/types/i18n';

export function getPhoneUrl(phone: string | undefined, locale: Locale): string {
  const number = phone?.match(/\d+/g)?.join();

  if (!number) {
    return '';
  }

  if (locale === countryCodes.mx && number.length === 9) {
    return `${commonValidation.tel52}-${number.substring(0, 3)}-${number.substring(
      3,
      6
    )}-${number.substring(6)}`;
  }

  if (locale === countryCodes.ptBr) {
    const groupEnd = number.length === 9 ? 7 : 6;
    return `${commonValidation.tel55}-${number.substring(0, 2)}-${number.substring(
      2,
      groupEnd
    )}-${number.substring(groupEnd)}`;
  }

  return `${commonValidation.tel1}-${number.substring(0, 3)}-${number.substring(
    3,
    6
  )}-${number.substring(6)}`;
}
