/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsPageNames } from '@/constants/analytics/analyticsPageNames';
import { analyticsPageType } from '@/constants/analytics/analyticsPageType';
import type { PageName, SEOPageType } from '@/types/analytics';
import { siteSections } from '@/utils/siteSections';
import { analyticsDataLayerVariable } from '../analyticsDataLayerVariable';
import { seoPageType } from './seoPageType';

export function pageTypeSiteSection(page: PageName, dynamicPageName: string = '') {
  const { data, seoPageTypesName } = globalPageTypeSiteSection(page, dynamicPageName);
  seoPageType(seoPageTypesName);
  analyticsDataLayerVariable(data);
}

const {
  azSavings,
  azProfile,
  azContactUs,
  azOrderHistory,
  azRewardsLanding,
  azOrderDetails,
  azServiceHistory,
  azTrackOrder,
  azMiniCart,
  azRewardsCredit,
  azRecalls,
  azrepairHelp,
  azGuide,
  azVehicles,
  azMyzone,
  azContinueAsGuest,
  azShopByMake,
  azOrderConfirmation,
  azHome,
  azFullCart,
  azMyStore,
  azMystoreLocator,
  azStoreDetails,
  azYmmeOverlay,
  azCheckout,
  azGiftCardBalance,
} = analyticsPageNames;

const {
  dealsDealsSavings,
  myzoneProfile,
  customerServiceContactUs,
  myzoneOrderHistory,
  myzoneRewardsLanding,
  myzoneOrderDetails,
  myzoneVehiclesServiceHistory,
  myzoneTrackOrder,
  myzoneRewardsCredit,
  repairHelp,
  repairHelpMaintenanceGuide,
  myzoneVehicles,
  myzone,
  catalogShopByMake,
  catalogShelf,
  overlay,
  customerServiceRecalls,
  checkout,
  giftCardBalance,
} = analyticsPageType;

const {
  siteSectionCartAndCheckout,
  siteSectionMyzone,
  siteSectionCatalog,
  siteSectionHome,
  siteSectionContinueAsGuest,
  siteSectionOther,
  siteSectionDeals,
  siteSectionCart,
  siteSectionMyStore,
  siteSectionMyStoreLocator,
  siteSectionStoreDetails,
  siteSectionContactUs,
  siteSectionRepairHelp,
} = siteSections;

type PageTypeSectionData = {
  pageName: string;
  pageType: string;
  siteSection: string;
};

export function globalPageTypeSiteSection(
  page: PageName,
  dynamicPageName: string = ''
): {
  data: PageTypeSectionData;
  seoPageTypesName: SEOPageType;
} {
  let data: PageTypeSectionData;
  let seoPageTypesName: SEOPageType = 'other';

  switch (page) {
    case 'dealsAndSavings':
      data = {
        pageType: dealsDealsSavings,
        siteSection: siteSectionDeals,
        pageName: azSavings,
      };
      break;
    case 'myProfile':
      data = {
        pageType: myzoneProfile,
        siteSection: siteSectionMyzone,
        pageName: azProfile,
      };
      break;
    case 'contactus':
      data = {
        pageType: customerServiceContactUs,
        siteSection: siteSectionContactUs,
        pageName: azContactUs,
      };
      break;
    case 'orderHistory':
      data = {
        pageType: myzoneOrderHistory,
        siteSection: siteSectionMyzone,
        pageName: azOrderHistory,
      };
      break;
    case 'rewardsLanding':
      data = {
        pageType: myzoneRewardsLanding,
        siteSection: siteSectionMyzone,
        pageName: azRewardsLanding,
      };
      break;
    case 'orderDetails':
      data = {
        pageType: myzoneOrderDetails,
        siteSection: siteSectionMyzone,
        pageName: azOrderDetails,
      };
      break;
    case 'serviceHistory':
      data = {
        pageType: myzoneVehiclesServiceHistory,
        siteSection: siteSectionMyzone,
        pageName: azServiceHistory,
      };
      break;
    case 'trackOrder':
      data = {
        pageName: azTrackOrder,
        siteSection: siteSectionMyzone,
        pageType: myzoneTrackOrder,
      };
      break;
    case 'miniCart':
      data = {
        pageName: azMiniCart,
        siteSection: siteSectionCartAndCheckout,
        pageType: 'cart',
      };
      break;
    case 'rewardsCredit':
      data = {
        pageName: azRewardsCredit,
        siteSection: siteSectionMyzone,
        pageType: myzoneRewardsCredit,
      };
      break;
    case 'recalls':
      data = {
        pageName: azRecalls,
        siteSection: siteSectionContactUs,
        pageType: customerServiceRecalls,
      };
      break;
    case 'repairGuide':
      data = {
        pageName: azrepairHelp,
        siteSection: siteSectionRepairHelp,
        pageType: repairHelp,
      };
      break;
    case 'maintenanceGuide':
      data = {
        pageName: azGuide,
        siteSection: siteSectionRepairHelp,
        pageType: repairHelpMaintenanceGuide,
      };
      break;
    case 'myVehicles':
      data = {
        pageName: azVehicles,
        siteSection: siteSectionMyzone,
        pageType: myzoneVehicles,
      };
      break;
    case 'myAccount':
      data = {
        pageName: azMyzone,
        siteSection: siteSectionMyzone,
        pageType: myzone,
      };
      break;
    case 'continueAsGuest':
      data = {
        pageName: azContinueAsGuest,
        siteSection: siteSectionContinueAsGuest,
        pageType: analyticsPageType.continueAsGuest,
      };
      break;
    case 'shopByMake':
      data = {
        pageName: azShopByMake,
        siteSection: siteSectionCatalog,
        pageType: catalogShopByMake,
      };
      break;
    case 'home':
      seoPageTypesName = 'HOME';
      data = {
        pageName: azHome,
        siteSection: siteSectionHome,
        pageType: analyticsPageType.home,
      };
      break;
    case 'pdp':
      seoPageTypesName = 'PDP';
      data = {
        pageName: `az:catalog:${dynamicPageName}`,
        siteSection: siteSectionCatalog,
        pageType: analyticsPageType.catalogProductDetail,
      };
      break;
    case 'mystore':
      data = {
        pageName: azMyStore,
        pageType: analyticsPageType.myStore,
        siteSection: siteSectionMyStore,
      };
      break;
    case 'mystorelocator':
      data = {
        pageName: azMystoreLocator,
        pageType: analyticsPageType.myStoreLocator,
        siteSection: siteSectionMyStoreLocator,
      };
      break;
    case 'storedetails':
      data = {
        pageName: azStoreDetails,
        pageType: analyticsPageType.storeDetails,
        siteSection: siteSectionStoreDetails,
      };
      break;
    case 'cart':
      data = {
        pageType: analyticsPageType.cart,
        pageName: azFullCart,
        siteSection: siteSectionCart,
      };
      break;
    case 'az:order-confirmation':
      data = {
        pageType: analyticsPageType.orderConfirmation,
        pageName: azOrderConfirmation,
        siteSection: siteSectionCart,
      };
      break;
    case 'shelf':
      data = {
        pageType: catalogShelf,
        pageName: `az:catalog:${dynamicPageName}`,
        siteSection: siteSectionCatalog,
      };
      break;
    case 'ymme':
      data = {
        pageType: overlay,
        pageName: azYmmeOverlay,
        siteSection: siteSectionMyzone,
      };
      break;
    case 'checkout':
      data = {
        pageType: checkout,
        pageName: azCheckout,
        siteSection: siteSectionCartAndCheckout,
      };
      break;
    case 'giftcardbalance':
      data = {
        pageType: giftCardBalance,
        pageName: azGiftCardBalance,
        siteSection: siteSectionMyzone,
      };
      break;
    default:
      const otherPageType: SEOPageType = 'other';
      data = {
        pageName: `az:${page}`,
        pageType: otherPageType,
        siteSection: siteSectionOther,
      };
  }

  return {
    data,
    seoPageTypesName,
  };
}
