/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';

interface StoreDistanceProps {
  locIndex: number;
  locationDistances: {
    distanceKilometers: number;
    distanceMiles: number;
    id: string;
  }[];
}

const labelsMap = {
  milesLabel: 'label_MyAccountMyProfile_vehicle_Miles',
  kmLabel: 'label_StoreLocator_modal_Km',
};

export const StoreDistance = ({ locIndex, locationDistances }: StoreDistanceProps) => {
  const locale = useLocale();
  const labels = useLabels(labelsMap);

  if (
    locationDistances?.[locIndex]?.distanceMiles &&
    locale !== countryCodes.mx &&
    locale !== countryCodes.ptBr
  ) {
    return (
      <span>{`${locationDistances[locIndex].distanceMiles.toFixed(1)} ${labels.milesLabel}`}</span>
    );
  } else if (locationDistances?.[locIndex]?.distanceKilometers) {
    return (
      <span>
        {`${locationDistances[locIndex].distanceKilometers.toFixed(1)} ${labels.kmLabel}`}
      </span>
    );
  }

  return null;
};
