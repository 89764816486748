/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import cx from 'classnames';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { SmartLink as Link } from '../../utils/smartLink';
import azCommonStyles from '../../theme/globals.module.scss';
import { routePaths } from '../../constants/routePaths';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useFeatureFlag } from '@/features/globalConfig';
const twentyFourServiceImg = {
  src: '/images/24.svg',
};

export const warrantyImg = {
  src: '/images/warranty.svg',
};

export const batteryChargeImg = {
  src: '/images/battery-charge.svg',
};

export const toolsImg = {
  src: '/images/tools.svg',
};

export const oilCanImg = {
  src: '/images/oil-can.svg',
};

export const batteryServiceImg = {
  src: '/images/battery.svg',
};

export const herramientasImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/HERRAMIENTAS-01.svg',
};

export const serviciosGratuitosImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/SERVICIOS-GRATUITOS.svg',
};

export const sltsImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/SLTS-03-01.svg',
};

export const cargaDeBateriasImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/CARGA-DE-BATERIAS-04-01.svg',
};

export const checkEngineImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/CHECK-ENGINEE-05.svg',
};

export const batteryIconMx = {
  src: 'https://www.autozone.com.mx/cdn/images/MF/instore-service/Revision-y-carga-baterias.svg',
};

export const handToolIconMx = {
  src: 'https://www.autozone.com.mx/cdn/images/MF/instore-service/Prestamo-de-herramientas.svg',
};

export const bopusIconMx = {
  src: 'https://www.autozone.com.mx/cdn/images/MF/instore-service/Compra-en-linea.svg',
};

export const checkEngineIconMx = {
  src: 'https://www.autozone.com.mx/cdn/images/MF/instore-service/fixfinder.svg',
};

export const carHoodOpenIcon = {
  src: 'https://www.autozone.com.mx/cdn/images/MF/instore-service/Revision-marchas-y-alternadores.svg',
};

const brazilServices = {
  chargeBattery: {
    src: '/images/br/services/charge-battery.svg',
  },
  wiperBlades: {
    src: '/images/br/services/wiper-blades.svg',
  },
  headlightBulb: {
    src: '/images/br/services/headlight-bulb.svg',
  },
  checkEngine: {
    src: '/images/br/services/check-engine.svg',
  },
  oilChange: {
    src: '/images/br/services/oil-change.svg',
  },
};

export type Props = {
  handleClose: () => void;
};

function StoreServicesComp(props: Props) {
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const label_store_services_checkEngineMx = useLabel('label_store_details_mx_check_engine');
  const label_store_services_fixFinder = useLabel('label_store_details_mx_fix-finder');
  const label_store_services_battery_charging = useLabel('label_store_services_battery_charging');
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const isStoreMxBopus = useFeatureFlag('STOREINFO_MX_BOPUS_FF') == 'true';
  const isBopusMexicoAndFlagEnable = isBopusMexicoStoreEnabled && isStoreMxBopus;
  const usServicesRowOne = [
    {
      id: 1,
      imgSrc: twentyFourServiceImg.src,
      label: useLabel('label_store_services_free_testing'),
    },
    {
      id: 2,
      imgSrc: warrantyImg.src,
      label: useLabel('label_store_services_nationwide_warranty'),
    },
    {
      id: 3,
      imgSrc: batteryChargeImg.src,
      label: useLabel('label_store_services_free_battery_charging'),
    },
  ];
  const usServicesRowTwo = [
    {
      id: 4,
      imgSrc: toolsImg.src,
      label: useLabel('label_store_services_loan_a_tool'),
    },
    {
      id: 5,
      imgSrc: oilCanImg.src,
      label: useLabel('label_store_services_oil_recycling'),
    },
    {
      id: 6,
      imgSrc: batteryServiceImg.src,
      label: useLabel('label_store_services_battery_recycling'),
    },
  ];
  const mxServicesRowOne = [
    {
      id: 1,
      imgSrc: isBopusMexicoAndFlagEnable ? batteryIconMx.src : herramientasImg.src,
      label: useLabel(
        isBopusMexicoAndFlagEnable
          ? 'label_store_details_mx_battery'
          : 'label_store_services_loan_a_tool'
      ),
    },
    {
      id: 2,
      imgSrc: isBopusMexicoAndFlagEnable ? handToolIconMx.src : serviciosGratuitosImg.src,
      label: useLabel(
        isBopusMexicoAndFlagEnable
          ? 'label_store_details_mx_hand_tool'
          : 'label_store_services_battery_test'
      ),
    },
    {
      id: 3,
      imgSrc: isBopusMexicoAndFlagEnable ? bopusIconMx.src : sltsImg.src,
      label: useLabel(
        isBopusMexicoAndFlagEnable
          ? 'label_orderOnline_pickup_at_store'
          : 'label_store_services_send_list_to_store'
      ),
    },
  ];
  const mxServicesRowTwo = [
    {
      id: 4,
      imgSrc: isBopusMexicoAndFlagEnable ? checkEngineIconMx.src : cargaDeBateriasImg.src,
      label: isBopusMexicoAndFlagEnable ? (
        <div>
          {label_store_services_checkEngineMx} <br />
          {label_store_services_fixFinder}
          <sup>SM</sup>
        </div>
      ) : (
        <div>{label_store_services_battery_charging}</div>
      ),
    },
    {
      id: 5,
      imgSrc: isBopusMexicoAndFlagEnable ? carHoodOpenIcon.src : checkEngineImg.src,
      label: useLabel(
        isBopusMexicoAndFlagEnable
          ? 'label_store_details_mx_car_hood_open'
          : 'label_store_services_scanning_services'
      ),
    },
  ];
  const brServicesRowOne = [
    {
      id: 1,
      imgSrc: brazilServices.chargeBattery.src,
      label: useLabel('label_store_services_battery_charging_br'),
    },
    {
      id: 2,
      imgSrc: brazilServices.wiperBlades.src,
      label: useLabel('label_store_services_wiper_blades_br'),
    },
    {
      id: 3,
      imgSrc: brazilServices.headlightBulb.src,
      label: useLabel('label_store_services_headlight_bulb_br'),
    },
  ];
  const brServicesRowTwo = [
    {
      id: 4,
      imgSrc: brazilServices.checkEngine.src,
      label: useLabel('label_store_services_check_engine_br'),
    },
    {
      id: 5,
      imgSrc: brazilServices.oilChange.src,
      label: useLabel('label_store_services_oil_change'),
    },
  ];

  const services = {
    [countryCodes.us]: {
      rowOne: usServicesRowOne,
      rowTwo: usServicesRowTwo,
    },
    [countryCodes.mx]: {
      rowOne: mxServicesRowOne,
      rowTwo: mxServicesRowTwo,
    },
    [countryCodes.ptBr]: {
      rowOne: brServicesRowOne,
      rowTwo: brServicesRowTwo,
    },
  };

  const isBR = locale === countryCodes.ptBr;
  const servicesRowOne = services[locale]?.rowOne;
  const servicesRowTwo = services[locale]?.rowTwo;
  const inStoreServicesLink = {
    [countryCodes.us]: routePaths.landingPageInStoreServices,
    [countryCodes.mx]: routePaths.landingPageInStoreServicesMX,
    [countryCodes.ptBr]: routePaths.landingPageInStoreServicesBR,
  }[locale];

  const renderImages = () => (
    <ul className={styles.renderImages}>
      {servicesRowOne.map((service) => (
        <li className={styles.storeContainerOfImages} key={service.id}>
          <img className={styles.storeImage} src={service.imgSrc} alt="" />
          <div
            className={cx(
              styles.subTitle,
              !isBR && azCommonStyles['az-margin-top-xxs'],
              azCommonStyles['az-caption']
            )}
          >
            {service.label}
          </div>
        </li>
      ))}
      {servicesRowTwo.map((service) => (
        <li
          className={
            isBopusMexicoAndFlagEnable
              ? cx(styles.storeContainerOfImages, styles.serviceRowTwoWidth)
              : styles.storeContainerOfImages
          }
          key={service.id}
        >
          <img className={styles.storeImage} src={service.imgSrc} alt="" />
          <div
            className={cx(
              styles.subTitle,
              !isBR && azCommonStyles['az-margin-top-xxs'],
              azCommonStyles['az-caption']
            )}
          >
            {service.label}
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <Fragment>
      <div
        id="store-services-container"
        data-testid="store-services-container"
        className={styles.container}
      >
        <div className={cx(styles.topLine, azCommonStyles['az-margin-bottom-xxs'])}>
          <h2
            className={cx(
              azCommonStyles['az-title-5-medium'],
              azCommonStyles['az-button-text'],
              styles.myStoreServicesTitle
            )}
          >
            <Label label="label_store_storeservices" />
          </h2>
          <Link
            onClick={props.handleClose}
            to={inStoreServicesLink}
            className={azCommonStyles['az-body-2-link']}
            aria-label="Find out more about this store's services"
          >
            <Label label="Label_store_findoutmore" />
          </Link>
        </div>
      </div>
      {renderImages()}
      {isBR && (
        <p className={cx(azCommonStyles['az-caption'], styles.autozonerInfo)}>
          <Label label="label_store_autozoner_info" />
        </p>
      )}
    </Fragment>
  );
}
StoreServicesComp.displayname = 'StoreServices';

export default StoreServicesComp;
