/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLabels } from '@/hooks/useLabels';
import { useEffect, useRef } from 'react';
import styles from './styles.module.scss';

interface SetStoreButtonProps {
  isGetRef?: boolean;
  nearByStores: boolean;
  onClick: () => void;
  locIndex: number;
  itemPresentInCart?: boolean;
  size: 'small' | 'large';
  storeId: string;
}

const labelsMap = {
  lblSelectStoreAndAddToCart: 'label_SelectStore_and_AddToCart',
  lblSetYourStore: 'label_TopNav_content_SetYourStore',
  ariaLblSetYourStore: 'aria_label_set_your_store_button',
  ariaLblSelectStoreAndToCart: 'aria_label_select_store_and_to_cart_button',
};

export const SetStoreButton = ({
  locIndex,
  nearByStores,
  isGetRef,
  itemPresentInCart,
  onClick,
  size,
  storeId,
}: SetStoreButtonProps) => {
  const getRef = useRef<HTMLAnchorElement>(null);
  const labels = useLabels(labelsMap);
  const sisterStoreFlyout = useFeatureFlag('SISTER_STORE_FLYOUT_ENABLED') === 'true';

  useEffect(() => {
    if (isGetRef && getRef.current !== null) {
      getRef.current?.focus();
    }
  }, [isGetRef]);

  let ariaLabel = labels.ariaLblSetYourStore.replace(':storeId', storeId);
  let label = labels.lblSetYourStore;
  let customClass = '';

  if (nearByStores && sisterStoreFlyout && !itemPresentInCart) {
    ariaLabel = labels.ariaLblSelectStoreAndToCart.replace(':storeId', storeId);
    label = labels.lblSelectStoreAndAddToCart;
    customClass = styles.setStoreAndAddToCartBtn;
  }

  return (
    <Button
      onClick={onClick}
      variant="contained"
      data-test="click2"
      id="setStore"
      aria-label={ariaLabel}
      customClass={customClass}
      data-testid={`set-store-btn-${locIndex}`}
      size={size}
    >
      <p>{label}</p>
    </Button>
  );
};
