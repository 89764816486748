/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export function convertToAmPm(time: string, abbreviateTime: boolean): string {
  const [hours, minutes] = time.split(':');
  const hoursNum = parseInt(hours);

  if (hoursNum === 0) {
    return `12:${minutes} AM`;
  } else if (hoursNum === 12) {
    return `12:${minutes} PM`;
  } else if (hoursNum > 12) {
    return abbreviateTime ? `${hoursNum - 12} PM` : `${hoursNum - 12}:${minutes} PM`;
  }

  return `${hours}:${minutes} AM`;
}
