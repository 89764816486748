/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { routePaths } from '../../constants/routePaths';

export function getMapUrl(destAddr: string): string {
  const URLQuery = new URLSearchParams({
    destination: destAddr,
  });
  return `${routePaths.mapURL}${URLQuery.toString()}`;
}
