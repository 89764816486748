/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Hidden from '../../Hidden';
import styles from './styles.module.scss';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import { type YextGeoSearchResponse } from '@/features/fulfillment';

type Props = {
  initialSearchScreen: boolean;
  selectedTab: string;
  mapContainer: {
    current: any | HTMLDivElement;
  };
  storeData: YextGeoSearchResponse | undefined;
};

const StoreMap = ({ initialSearchScreen, selectedTab, mapContainer, storeData }: Props) => {
  const locale = useLocale();
  const { data: storeDataBopusMx } = useStoreDetailsData();
  const isStoreMxBopus = useFeatureFlag('STOREINFO_MX_BOPUS_FF') == 'true';
  const isBopusEnabled = storeDataBopusMx?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const isBopusMexicoAndFlagEnable = isBopusMexicoStoreEnabled && isStoreMxBopus;
  const renderStoreMap = (initialSearchScreen: boolean) => {
    const numOfStores = storeData?.response?.locations.length ?? 0;
    const storesAvailable = numOfStores > 0;
    let mapStyles = '';

    if (initialSearchScreen || !storesAvailable) {
      mapStyles = `${styles.mapOnInitScreenHeight} ${
        isBopusMexicoAndFlagEnable
          ? styles.mapReplacementStoreMapBopusEnable
          : styles.mapReplacement
      } ${
        isBopusMexicoAndFlagEnable
          ? styles.mapReplacementNoStoreBopusEnabled
          : styles.mapReplacementNoStore
      }`;
    } else {
      mapStyles = `${
        isBopusMexicoAndFlagEnable
          ? styles.mapReplacementStoreMapBopusEnable
          : styles.mapReplacement
      }`;
    }

    if (storeData === undefined || !storesAvailable) {
      return <div className={mapStyles} />;
    }

    return (
      <div
        className={
          isBopusMexicoAndFlagEnable
            ? styles.mapReplacementStoreMapBopusEnable
            : styles.mapReplacement
        }
      />
    );
  };

  let selectedMap = '';

  if (selectedTab === 'map') {
    selectedMap = styles.selected;
  }

  const defaultNode = (
    <div ref={mapContainer} className={`${styles.map} ${selectedMap}`}>
      {renderStoreMap(initialSearchScreen)}
    </div>
  );

  if (selectedTab === 'map' || initialSearchScreen) {
    return defaultNode;
  }

  return (
    <Hidden implementation="js" mdDown>
      {defaultNode}
    </Hidden>
  );
};

export default StoreMap;
