/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export function memoize(fn: any): (arg: any, ...rest: Array<any>) => any {
  const cache = new Map();
  return (arg: any, ...rest: Array<any>) => {
    const cached = cache.get(arg);

    if (cached) {
      return cached;
    }

    const val = fn(arg, ...rest);
    cache.set(arg, val);
    return val;
  };
}
