/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const splitStringToTwoLabels = (
  currentLabel: string,
  isUpperCase?: boolean
): {
  firstLabel: string;
  secondLabel: string;
} => {
  let [firstLabel, ...secondLabel] = currentLabel.split(' ');

  if (secondLabel) {
    // @ts-expect-error TODO: fix type error
    secondLabel = isUpperCase
      ? secondLabel.join(' ').toUpperCase() || ''
      : secondLabel.join(' ') || '';
  }

  if (firstLabel) {
    firstLabel = isUpperCase ? firstLabel.toUpperCase() || '' : firstLabel || '';
  }

  return {
    firstLabel,
    // @ts-expect-error TODO: fix type error
    secondLabel,
  };
};
