/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useCallback, useEffect, useRef, useContext } from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { useFeatureFlag } from '@/features/globalConfig';
import { sessionStorage } from '@/utils/sessionStorage';
import { StoreStatus } from './components/StoreStatus';
import { StoreAddress } from './components/StoreAddress';
import { StoreDistance } from './components/StoreDistance';
import { StoreNumber } from './components/StoreNumber';
import { isStoreOpen } from './helpers';
import { isSafari } from '../../../utils/common';
import { StoreDirections } from './components/StoreDirections';
import { SetStoreButton } from './components/SetStoreButton/SetStoreButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { capitalizePhrase } from '@/utils/capitalizePhrase';
import { SnackBarContext } from '@/components/SnackBarContext';
import { PrevStoreObject } from '@/components/StoreLocator/types';

type StoreProps = {
  searchedInput: string;
  isGetRef?: boolean;
  storeData: any;
  setStore: (
    storeNumber: string,
    zipCode: string,
    isNearByStoreSelected: boolean,
    searchedInput?: string
  ) => void;
  setMoreDetails: (moreDetails: string | null) => void;
  selectedStore: number;
  address: any;
  locIndex: number;
  fromLat: number;
  fromLng: number;
  phone: string;
  storeId: string;
  locationDistances: any;
  nearByStores: boolean;
  focusMoreDetailsBtn?: string;
  skuId?: string;
  itemPresentInCart?: boolean;
  prevStore?: PrevStoreObject;
};

const labelsMap = {
  noStoreFound:
    'label_Footer_modal_WeCouldnTFindAnyStoresWithin150MilesOfYourSearchPleaseTryAgainOrBrowseAllStoreLocationsInOur',
  your: 'label_MyAccountMyProfile_landing_RewardYour',
  store: 'label_StoreLocator_modal_Store',
  storeDirectory: 'label_StoreLocator_modal_StoreDirectory',
  dealsNearYour: 'label_Footer_modal_SearchAndSetAStoreToSeeProductPricesAndDealsNearYou',
  moreDetails: 'label_Footer_modal_MoreDetails',
  storeClosed: 'label_StoreLocator_modal_CLOSED',
  lblYourStoreHasBeenUpdatedTo: 'label_your_store_has_been_updated_to',
};

export const Store = ({
  searchedInput,
  isGetRef,
  storeData,
  setStore,
  setMoreDetails,
  selectedStore,
  address,
  locIndex,
  fromLat,
  fromLng,
  phone,
  storeId,
  locationDistances,
  nearByStores,
  focusMoreDetailsBtn,
  skuId,
  itemPresentInCart,
  prevStore,
}: StoreProps) => {
  const locale = useLocale();
  const labels = useLabels(labelsMap);
  const sisterStoreFlyout = useFeatureFlag('SISTER_STORE_FLYOUT_ENABLED') === 'true';
  const searchRadius = useFeatureFlag('STORE_SEARCH_RADIUS') || '150';
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const { showSnackBar: showSnackBarContext } = useContext(SnackBarContext);

  const moreDetailsButtonRef = useRef<HTMLDivElement>(null);

  const onSetStoreClick = useCallback(() => {
    if (nearByStores && sisterStoreFlyout) {
      sessionStorage.setItem('addOrUpdateCart', 'true');
      sessionStorage.setItem('skuId', skuId ?? '');
    }

    const isSameAsPrevStore =
      prevStore?.prevStoreNumber === storeId ||
      prevStore?.prevAddress1?.toLowerCase() === address.toLowerCase();

    if (!isSameAsPrevStore) {
      showSnackBarContext(`${labels.lblYourStoreHasBeenUpdatedTo} ${capitalizePhrase(address)}.`);
    }
    setStore(storeId, storeData?.zip, nearByStores, searchedInput);
  }, [
    nearByStores,
    setStore,
    searchedInput,
    skuId,
    sisterStoreFlyout,
    storeData?.zip,
    storeId,
    labels.lblYourStoreHasBeenUpdatedTo,
    prevStore,
    showSnackBarContext,
    address,
  ]);

  useEffect(() => {
    if (focusMoreDetailsBtn === storeId) {
      moreDetailsButtonRef?.current?.focus();
    }
  }, [focusMoreDetailsBtn, storeId]);

  if (!storeData) {
    if (searchedInput !== '' && nearByStores === false) {
      return (
        <p
          className={cx(azCommonStyles['az-body-2-regular'], styles.listMessage)}
          data-testid="store-not-found"
        >
          {labels.noStoreFound.replace(/150/gi, searchRadius)} <span>{labels.storeDirectory}</span>
        </p>
      );
    }

    return (
      <p className={cx(azCommonStyles['az-body-2-regular'], styles.listMessage)}>
        {labels.dealsNearYour}
      </p>
    );
  }

  const storeSelected = selectedStore === locIndex ? styles.selected : '';

  const storeHourData = (({ hours, holidayHours, locale }) => ({
    hours,
    holidayHours,
    locale,
  }))(storeData);

  // here is the store opening hour
  const hourCalculation = storeData.messageToDisplay
    ? storeData.messageToDisplay
    : storeData.hours
    ? StoreStatus({ storeHourData })
    : storeData.storeFullHours && StoreStatus({ storeHourData });

  const isOpen = isStoreOpen(storeHourData, locale);
  const storeStatusStyle = isOpen ? styles.storeOpen : styles.storeClosed;

  const handleClick = () => {
    setMoreDetails(storeId);
  };

  return (
    <li className={storeSelected}>
      <div className={styles.storeData}>
        <div className={styles.timeDistance}>
          <span className={storeStatusStyle}>{hourCalculation || labels.storeClosed}</span>
          <span className={styles.distance}>
            <StoreDistance locIndex={locIndex} locationDistances={locationDistances} />
          </span>
        </div>
        <StoreAddress storeData={storeData} locIndex={locIndex} address={address} />
        <StoreNumber storeId={storeId} />
        <StoreDirections
          isGetRef={isGetRef}
          fromLat={fromLat}
          fromLng={fromLng}
          phone={phone}
          storeId={storeId}
          storeData={storeData}
          address={address}
        />
        <div className={styles.btnContainer}>
          <Grid
            container
            className={
              sisterStoreFlyout && nearByStores
                ? itemPresentInCart
                  ? cx(azCommonStyles['az-margin-top-xxs'], styles.tabBtn)
                  : cx(azCommonStyles['az-margin-top-xxs'], styles.sisterStoreFlyoutTabBtn)
                : cx(azCommonStyles['az-margin-top-xxs'], styles.tabBtn)
            }
          >
            <div className={styles.storeButtonOne}>
              <SetStoreButton
                locIndex={locIndex}
                nearByStores={nearByStores}
                isGetRef={isGetRef}
                itemPresentInCart={itemPresentInCart}
                onClick={onSetStoreClick}
                storeId={storeId}
                size={matchesTablet ? 'large' : 'small'}
              />
            </div>
            <div
              className={
                sisterStoreFlyout && nearByStores
                  ? itemPresentInCart
                    ? cx(styles.storeButtonTwo, styles.moreDetailsFlyout)
                    : cx(styles.storeButtonTwoFlyout, styles.moreDetailsFlyout)
                  : styles.storeButtonTwo
              }
            >
              <div
                className={cx(styles.btnMoreWidth, {
                  [styles.safariBtnStyles]: isSafari(),
                })}
              >
                <Button
                  onClick={handleClick}
                  variant={
                    !matchesTablet || (sisterStoreFlyout && nearByStores) ? 'ghost' : 'outlined'
                  }
                  aria-label={`Get more details for store #${storeId}`}
                  reference={moreDetailsButtonRef}
                  rightArrow={!matchesTablet || (sisterStoreFlyout && nearByStores)}
                >
                  <p>{labels.moreDetails}</p>
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </div>
      <div
        className={cx(
          styles.listItem,
          azCommonStyles['az-margin-left-4xs'],
          azCommonStyles['az-margin-right-4xs']
        )}
      >
        <hr />
      </div>
    </li>
  );
};
