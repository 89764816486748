/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useState } from 'react';
import { StoreHourData } from '@/components/StoreLocator/types';
import dayjs from 'dayjs';
import { daysArray } from '@/constants/date';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { convertToAmPm } from '@/utils/convertToAmPm';
import { Fragment } from 'react';
import { convertStoreHourData } from '../../helpers';
import styles from './styles.module.scss';
import { countryCodes } from '@/constants/locale';

interface StoreStatusProps {
  storeHourData: StoreHourData;
}

const labelsMap = {
  storeClosed: 'label_StoreLocator_modal_CLOSED',

  storeOpen: 'label_StoreLocator_modal_OPEN',

  until: 'label_Footer_modal_Until',

  next: 'label_MyAccountMyProfile_serviceHistory_Next',

  tomorrow: 'label_StoreLocator_modal_Tomorrow',

  today: 'label_StoreLocator_modal_TODAY',

  midnight: 'label_StoreLocator_midnight',

  open24: 'label_Footer_modal_24Hours',

  Monday: 'label_StoreLocator_modal_Monday',

  Tuesday: 'label_StoreLocator_modal_Tuesday',

  Wednesday: 'label_StoreLocator_modal_Wednesday',

  Thursday: 'label_StoreLocator_modal_Thursday',

  Friday: 'label_StoreLocator_modal_Friday',

  Saturday: 'label_StoreLocator_modal_Saturday',

  Sunday: 'label_StoreLocator_modal_Sunday',
};

export const StoreStatus = ({ storeHourData }: StoreStatusProps): React.ReactElement => {
  const locale = useLocale();

  const {
    storeClosedAllDays,
    nextAvailableDay,
    openString,
    closedString,
    allRemainingStoresClosed,
    opensTomorrow,
  } = convertStoreHourData(storeHourData, locale);
  const daysOfTheWeek = daysArray[locale];

  // Gives the current time as a dayjs object
  const currentTime = dayjs();

  // Gives the current day as a number (Sunday is 0, Monday is 1 ... Saturday is 6.)
  const currentDay = dayjs().day();
  const abbreviateTime = false;

  const now = new Date();

  const labels = useLabels(labelsMap);

  now.setSeconds(0);

  // Gives the next available day as a number (Sunday is 0, Monday is 1 ... Saturday is 6.)
  const [currentDayFormatted] = useState(daysOfTheWeek[currentDay]);

  const openTime = new Date();

  openTime.setHours(
    parseInt(openString?.split(':')?.[0]),
    parseInt(openString?.split(':')?.[1]),
    0
  );
  const closeTime = new Date();

  closeTime.setHours(
    parseInt(closedString?.split(':')?.[0]),

    parseInt(closedString?.split(':')?.[1]),

    0
  );

  // Hours are counted from 0 - 23 so if we set 00:00 we need to also increase day number
  if (closedString === '0:00') {
    closeTime.setDate(closeTime.getDate() + 1);
  }

  const isStoreOpen = closeTime > now && openTime < now;

  const nextAvailableDayIsToday = nextAvailableDay === currentDayFormatted;

  const closesAtMidnight = openString !== '0:00' && closedString === '0:00';

  const isOpen24Hours = openString === '0:00' && closedString === '0:00';

  const targetTime = dayjs().hour(23).minute(59); // 11:59 PM

  const startOfMidnight = currentTime.isAfter(targetTime); // will be false before 11:59 PM

  const storeAfterHours = now >= closeTime && !startOfMidnight;

  const nextAvailableDayIsTodayAndStoreClosed =
    nextAvailableDay === currentDayFormatted && storeAfterHours;

  let openTimeLabel = labels.today;

  if (nextAvailableDayIsTodayAndStoreClosed) {
    openTimeLabel = labels.tomorrow;
  } else if (startOfMidnight) {
    openTimeLabel = labels.tomorrow;
  }

  const closedTimeString =
    locale === countryCodes.ptBr ? closedString : convertToAmPm(closedString, abbreviateTime);
  const openTimeString =
    locale === countryCodes.ptBr ? openString : convertToAmPm(openString, abbreviateTime);

  /*
   * if the store is closed with no `openTime` or `closeTime`, it should just say Closed
   * if the store is open today and the `closeTime` is after now, then show the store is OPEN until [close time]
   * if the store is open today and the `closeTime` is midnight, then show the store is OPEN until midnight
   * if the store is closed today and the `openTime` is tomorrow then show the store is CLOSED until [open time] [tomorrow] CLOSED until 7:30 AM tomorrow
   * by default show the store is CLOSED until [future open time] [future day]
   */

  switch (true) {
    case nextAvailableDayIsToday:
      if (nextAvailableDayIsToday) {
        // Store is open 24 hours
        if (isOpen24Hours) {
          return <Fragment key="storeStatus">{`${labels.storeOpen} ${labels.open24}`}</Fragment>;
        }
        if (isStoreOpen) {
          // It's today and store is open - either show midnight or close time
          return (
            <span>
              {' '}
              <span key="storeStatus" className={styles.openText}>
                {`${labels.storeOpen}`} <span className={styles.untilText}>{labels.until}</span>
                {closesAtMidnight ? labels.midnight : closedTimeString}
              </span>
            </span>
          );
        } else {
          // It's today and store is closed
          return (
            <span>
              {' '}
              {storeClosedAllDays ? (
                <Fragment key="storeStatusClosed">{`${labels.storeClosed}`}</Fragment>
              ) : (
                <span key="storeStatus" className={styles.closeText}>
                  {`${labels.storeClosed}`} <span className={styles.untilText}>{labels.until}</span>
                  {openTimeString} {allRemainingStoresClosed || !opensTomorrow ? '' : openTimeLabel}
                  {allRemainingStoresClosed ? labels.next : ''}{' '}
                  {allRemainingStoresClosed || !opensTomorrow ? nextAvailableDay : ''}
                </span>
              )}
            </span>
          );
        }
      }
    case !nextAvailableDayIsToday:
      // Store is closed until next available day
      return (
        <span>
          {' '}
          {storeClosedAllDays ? (
            <Fragment key="storeStatusClosed">{`${labels.storeClosed}`}</Fragment>
          ) : (
            <span key="storeStatus" className={styles.closeText}>
              {`${labels.storeClosed}`} <span className={styles.untilText}>{labels.until}</span>
              {openTimeString} {opensTomorrow ? labels.tomorrow : nextAvailableDay}{' '}
            </span>
          )}
        </span>
      );

    default:
      // Opens tomorrow or sometime in the future including next week
      return (
        <span>
          {' '}
          {storeClosedAllDays ? (
            <Fragment key="storeStatusClosed">{`${labels.storeClosed}`}</Fragment>
          ) : (
            <span key="storeStatus" className={styles.closeText}>
              {`${labels.storeClosed}`} <span className={styles.untilText}>{labels.until}</span>
              {openTimeString} {opensTomorrow ? labels.tomorrow : nextAvailableDay}{' '}
            </span>
          )}
        </span>
      );
  }
};
