/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import { getMapUrl, getPhoneUrl } from '@/utils/urlHelpers';
import { useEffect, useRef } from 'react';
import { trackGetDirectionInteraction } from '@/utils/analytics/track/trackGetDirectionInteraction';
import { trackStorePhoneInteraction } from '@/utils/analytics/track/trackStorePhoneInteraction';
import styles from './styles.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';

interface StoreDirectionsProps {
  isGetRef?: boolean;
  fromLat: number;
  fromLng: number;
  phone: string;
  storeId: string;
  storeData: any;
  address: any;
}

const labelsMap = {
  directionsToStore: 'label_Store_getDirectionsToStoreNumber',
  at: 'label_MyAccountMyProfile_orderhistory_At',
  zipCode: 'label_CreateAccount_body_ZipCode',
  getDirections: 'label_Footer_modal_GetDirections',
};

export const StoreDirections = ({
  isGetRef,
  fromLat,
  fromLng,
  phone,
  storeId,
  storeData,
  address,
}: StoreDirectionsProps) => {
  const locale = useLocale();
  const getRef = useRef<HTMLAnchorElement>(null);
  const labels = useLabels(labelsMap);

  useEffect(() => {
    if (isGetRef && getRef.current !== null) {
      getRef.current?.focus();
    }
  }, [isGetRef]);

  return (
    <div className={styles.links}>
      <a
        href={getMapUrl(`${fromLat},${fromLng}`)}
        ref={getRef}
        target="_blank"
        rel="noopener noreferrer"
        data-test="click1"
        data-testid="click1"
        onClick={trackGetDirectionInteraction}
        aria-label={`${labels.directionsToStore} ${storeId} ${labels.at} ${address} ${storeData.city} ${storeData.state} ${labels.zipCode} ${storeData.zip}`}
      >
        {labels.getDirections}
      </a>
      <a
        href={getPhoneUrl(phone, locale)}
        className={azCommonStyles['az-body-2-regular']}
        onClick={() => trackStorePhoneInteraction(phone)}
        data-test="click3"
        data-testid="click3"
      >
        {formatPhoneNumber(phone, locale)}
      </a>
    </div>
  );
};
