/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsDataLayerVariable } from '../analyticsDataLayerVariable';
import type { SEOPageType } from '@/types/analytics';

export function seoPageType(page: SEOPageType) {
  if (__IS_SERVER__) {
    return;
  }
  let seoPageType: string;
  const currentPath = window.location.pathname;

  switch (page) {
    case 'PDP':
      seoPageType = `PD:${currentPath}`;
      break;

    case 'SUBCATEGORY':
      seoPageType = `subCat:${currentPath}`;
      break;

    case 'CATEGORY':
      seoPageType = `Cat:${currentPath}`;
      break;

    case 'SHELF_MPT':
      seoPageType = `MPT:${currentPath}`;
      break;

    case 'SHELF_VPT':
      seoPageType = `VPT:${currentPath}`;
      break;

    case 'SHELF_PT':
      seoPageType = `PT:${currentPath}`;
      break;

    case 'SHELF_YVPT':
      seoPageType = `YVPT:${currentPath}`;
      break;

    case 'SHELF_YMMRP':
      seoPageType = `YMMRP:${currentPath}`;
      break;

    case 'HOME':
      seoPageType = 'HOME';
      break;

    case 'LANDING':
      seoPageType = `LP:${currentPath}`;
      break;

    case 'REPAIR':
      seoPageType = `RG:${currentPath}`;
      break;

    default:
      const otherPageType: SEOPageType = 'other';
      seoPageType = otherPageType;
  }

  analyticsDataLayerVariable({
    seoPageType,
  });
}
