/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import type { StoreFullHours, Schedule } from '@/types';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { convertToAmPm } from '@/utils/convertToAmPm';
import dayjs from 'dayjs';

interface HolidayData {
  date: string;
  hours: String;
  isRegularHours: boolean;
}

type YextDay = Schedule & {
  date: Date;
  closed: boolean;
  isOpenNow: boolean;
  open24Hours: boolean;
  isHolidayHours?: boolean;
  willOpenToday?: boolean;
};

type YextStoreFullHours = {
  Monday: YextDay;
  Thursday: YextDay;
  Friday: YextDay;
  Sunday: YextDay;
  Wednesday: YextDay;
  Tuesday: YextDay;
  Saturday: YextDay;
};

interface StoreSchedule {
  today: YextDay;
  storeFullHours: StoreFullHours;
}

const numberToDay = (dayNumber: number): keyof YextStoreFullHours => {
  switch (dayNumber) {
    case 1:
      return 'Sunday';
    case 2:
      return 'Monday';
    case 3:
      return 'Tuesday';
    case 4:
      return 'Wednesday';
    case 5:
      return 'Thursday';
    case 6:
      return 'Friday';
    case 7:
      return 'Saturday';
    default:
      return 'Sunday';
  }
};

const generateOpeningTime = (date: Date | string, openingHour: number, openingMinute: number) => {
  const openingTime = dayjs(date)
    .hour(Number(openingHour))
    .minute(Number(openingMinute))
    .second(0)
    .toDate();

  return openingTime;
};

const generateClosingTime = (date: Date | string, closingHour: number, closingMinute: number) => {
  const closingTimeDayShift = Number(closingHour) === 0 && Number(closingMinute) === 0 ? 1 : 0;
  return dayjs(date)
    .hour(Number(closingHour))
    .minute(Number(closingMinute))
    .second(0)
    .add(closingTimeDayShift, 'day')
    .toDate();
};

const generateBaseWeek = (): YextStoreFullHours => {
  const emptySchedule: YextDay = {
    id: '',
    open: '',
    close: '',
    date: new Date(),
    isOpenNow: false,
    closed: true,
    open24Hours: false,
  };
  let week = {
    Monday: emptySchedule,
    Tuesday: emptySchedule,
    Wednesday: emptySchedule,
    Thursday: emptySchedule,
    Friday: emptySchedule,
    Saturday: emptySchedule,
    Sunday: emptySchedule,
  };
  for (let index = 0; index < 7; index++) {
    const date = dayjs().add(index, 'day').toDate();
    week = {
      ...week,
      [date.toLocaleDateString('en-US', { weekday: 'long' })]: {
        id: date.getDay() + 1,
        closed: true,
        date: date,
        isOpenNow: false,
      },
    };
  }
  return week;
};

const addAvailabilityToBaseWeek = (
  availabilityString: string,
  baseWeek: YextStoreFullHours,
  locale?: Locale
): YextStoreFullHours => {
  const isBR = locale === countryCodes.ptBr;

  availabilityString?.split(',').forEach((daySchedule: string) => {
    const [day, openingHour, openingMinute, closingHour, closingMinute] = daySchedule.split(':');
    const dayName = numberToDay(Number(day));
    const openingTime = generateOpeningTime(
      baseWeek[dayName].date,
      Number(openingHour),
      Number(openingMinute)
    );
    const closingTime = generateClosingTime(
      baseWeek[dayName].date,
      Number(closingHour),
      Number(closingMinute)
    );

    const now = dayjs().second(0).toDate();

    baseWeek[dayName] = {
      ...baseWeek[dayName],
      open: isBR
        ? `${openingHour}:${openingMinute}`
        : convertToAmPm(`${openingHour}:${openingMinute}`, false),
      close: isBR
        ? `${closingHour}:${closingMinute}`
        : convertToAmPm(`${closingHour}:${closingMinute}`, false),
      isOpenNow: dayjs(openingTime).isBefore(now) && dayjs(closingTime).isAfter(now),
      closed: false,
      willOpenToday: dayjs(openingTime).isAfter(now),
      isHolidayHours: false,
      open24Hours:
        Number(openingHour) === 0 &&
        Number(openingMinute) === 0 &&
        Number(closingHour) === 0 &&
        Number(closingMinute) === 0,
    };
  });
  return baseWeek;
};

const addHolidayAvailabilityDataToCurrentWeek = (
  holidayAvailabilityData: HolidayData[],
  currentWeek: YextStoreFullHours
) => {
  holidayAvailabilityData.forEach((holiday) => {
    const [openingHour, openingMinute, closingHour, closingMinute] = holiday.hours.split(':');
    for (const key in currentWeek) {
      const element = currentWeek[key as keyof YextStoreFullHours];
      if (dayjs(element.date).isSame(holiday.date, 'day')) {
        element.isHolidayHours = true;
        if (!holiday.hours) {
          element.closed = true;
        }
        if (!holiday.isRegularHours) {
          element.open = convertToAmPm(`${openingHour}:${openingMinute}`, false);
          element.close = convertToAmPm(`${closingHour}:${closingMinute}`, false);
        }
      }
    }
  });
};

export const useYextStoreHours = (storeData: any): StoreSchedule => {
  const locale = useLocale();
  let storeFullHours = addAvailabilityToBaseWeek(storeData?.hours, generateBaseWeek(), locale);

  if (storeData?.holidayHours) {
    addHolidayAvailabilityDataToCurrentWeek(storeData?.holidayHours, storeFullHours);
  }

  const now = dayjs().second(0).toDate();

  return {
    storeFullHours,
    // JS get day starts on 0 with Sunday, but Yext starts in 1 with Sunday. Hence we need to add a day
    today: storeFullHours[numberToDay(now.getDay() + 1)],
  } as any;
};
